<script>
// some leaflet plugins (e.g. pixi) requires to be imported first
// before importing leaflet itself

/* eslint-disable-next-line no-unused-vars */
import LDraw from 'leaflet-draw'
/* eslint-disable-next-line no-unused-vars */
// import LPixi from 'leaflet-pixi-overlay'
// import * as PIXI from 'pixi.js'

import * as Vue2Leaflet from 'vue2-leaflet'
import Vue2LeafletMarkercluster from './Vue2LeafletMarkercluster'
import Vue2LeafletGoogleMutant from './Vue2LeafletGoogleMutant'
import { latLng, Icon, icon } from 'leaflet'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

import { default as pointInPolygon } from 'point-in-polygon'

import { mapGetters, mapState } from 'vuex'

import VehicleMarker from './VehicleMarker'
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils'
// function rand(n) {
//   let max = n + 0.1
//   let min = n - 0.1
//   return Math.random() * (max - min) + min
// }

export default {
  name: 'LiveMapNew',

  components: {
    'v-map': Vue2Leaflet.LMap,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-icondefault': Vue2Leaflet.LIconDefault,
    'v-icon': Vue2Leaflet.LIcon,
    'v-marker': Vue2Leaflet.LMarker,
    'v-tooltip': Vue2Leaflet.LTooltip,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    'v-control-zoom': Vue2Leaflet.LControlZoom,
    'v-control-layers': Vue2Leaflet.LControlLayers,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,

    VehicleMarker,
  },

  data() {
    // let locations = []
    // for (let i = 0; i < 200; i++) {
    //   locations.push({
    //     id: i,
    //     latlng: latLng(rand(23.9205), rand(90.953646)),
    //     text: 'Bike ' + i,
    //   })
    // }
    let customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl, shadowUrl })
    )
    return {
      icon: customicon,

      clusterOptions: {
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 15,
      },

      // initialLocation: latLng(23.9205, 90.953646),

      // transition for list page @see -> onNavigate()
      transitionName: '',

      areas: {
        service: [],
        parking: [],
        slowZone: [],
        restricted: [],
      },
      areasLoded: false,

      dbExpanded: false,
      isRealtimeEnabled: false,
      zoomLevel: null,
      apikey: 'AIzaSyDAM863ajWTCZg6n9ve4zVwnMLAAGY3nYA',
      mapViewOptions: {
        // type: 'satellite',
        type: 'roadmap',
      },
      mapTileProviders: [
        {
          name: 'Roadmap',
          type: 'roadmap',
          visible: false,
        },
        {
          name: 'Satellite',
          type: 'hybrid',
          visible: false,
        },
      ],
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
      ],
    }
  },

  computed: {
    // initialLocation
    id() {
      return this.$route.params.id
    },

    getMapCenterLatLng() {
      if (this.isRealtimeEnabled) {
        if (this.$store.getters['liveMap/getSelectedVehicleId']) {
          return this.getCurrentVehicleLatLng()
        } else {
          return this.getDefaultFleetLatLng()
        }
      } else {
        if (this.$store.getters['liveMap/getSelectedVehicleId']) {
          return this.getCurrentVehicleLatLng()
        } else {
          return this.getDefaultFleetLatLng()
        }
      }
    },
    getZoomLevel() {
      // if (this.zoomLevel > 10) return 18
      // else {
      //   return 10
      // }
      return 10
    },

    ...mapState({
      drawerView: (state) => state.liveMap.drawerView,
      drawerViews: (state) => state.liveMap.drawerViews,

      drawerVisibility: (state) => state.liveMap.drawerVisibility,
      drawerBottomVisibility: (state) => state.liveMap.drawerBottomVisibility,
      drawerRightVisibility: (state) => state.liveMap.drawerRightVisibility,

      reqInit: (state) => state.liveMap.req.init,
      reqBusy: (state) => state.liveMap.req.busy,

      filterFleet: (state) => state.liveMap.filterFleet,
      filterConnectivity: (state) => state.liveMap.filterConnectivity,
      filterBatteryStatus: (state) => state.liveMap.filterBatteryStatus,
      filterLockStatus: (state) => state.liveMap.filterLockStatus,
      filterVehicleStatus: (state) => state.liveMap.filterVehicleStatus,
      filterLastAppliedAt: (state) => state.liveMap.filterLastAppliedAt,
      realtimeLocationsOfSelectedVehicle: (state) =>
        state.liveMap.realtimeDataOfSelectedVehicle,
      //
      filteredFleet: (state) => state.liveMap.filteredFleet,
    }),

    ...mapGetters({
      resData: 'liveMap/getResData',
      fleetsData: 'liveMap/getFleetsData',
      getVehicleList: 'liveMap/getVehicleList',
      getFilteredData: 'liveMap/getFilteredData',
    }),
  },

  async created() {
    // todo: move service areas to store
    // await this.fetchAreas()
    // reqBusy is handled by the action
    await this.$store.dispatch('liveMap/fetchData', { isShowOnIframe: true })

    // console.log({ ser: this.areas.service })
    // console.log({ LiveMap: this.$store.state.liveMap })
  },

  mounted() {
    this.$refs.map.mapObject.on('zoomend', this.updateZoom)
    EventBus.$on('livemap-realtime-toggle-status', (status) => {
      console.log('livemap-realtime-toggle-status', status)
      this.isRealtimeEnabled = status
    })
    // leaflet map instance
    this.$nextTick(() => {
      const self = this
      this.map = this.$refs.map.mapObject
      setTimeout(function() {
        self.map.invalidateSize()
        console.log('invalidating')
      }, 2500)
      // console.log({ thisResData: this.resData })
      // console.log('areas', this.areas)
    })

    // update cluster options
    // setTimeout(() => {
    //   console.log('done')
    //   this.$nextTick(() => {
    //     this.clusterOptions = { disableClusteringAtZoom: 15 }
    //   })
    // }, 5000)

    // select vehicles by drawing
    this.$nextTick(() => {
      // FeatureGroup is to store different layers
      // Add a new FeatureGroup from the leaflet scope for drawing objects on map
      // let drawables = new window.L.FeatureGroup().addTo(this.map)
      this.drawables = new window.L.FeatureGroup()
      // console.log({ drawables: this.drawables })

      // this.map -> the non-reactive leaflet instance
      this.map.addLayer(this.drawables)

      // Create leaflet draw control menu

      this.map.on(window.L.Draw.Event.CREATED, (e) => {
        // remove all previous drawn layers
        this.drawables.clearLayers()

        // console.log(this.drawControl)
        // console.log(this.drawables)
        // console.log({ e })

        // this.drawControl.removeAllLayers()

        const type = e.layerType,
          layer = e.layer
        if (type === 'marker') {
          // Do marker specific actions
        }

        // add the drawn layer
        this.drawables.addLayer(layer)
        console.log({ drawEnd: e })

        // find markers
        let polygon = layer.getLatLngs()
        const area = polygon[0].map((x) => {
          return [x.lng, x.lat]
        })

        console.log(area)
        // console.log({ drawnLatLng: polygon })

        const markersInside = {}

        const vehicles = this.resData
        for (const key in vehicles) {
          const vehicle = vehicles[key]

          if (pointInPolygon(vehicle.geo.lngLat, area)) {
            vehicle.isSelected = true
            markersInside[key] = vehicle
          }
        }

        // this.locations.forEach((x) => {
        //   // needs to be [lng, lat]
        //   const curr = [x.latlng.lng, x.latlng.lat]
        //   pointInPolygon(curr, area) && markersInside.push(x)
        // })

        console.log({ markersInside })

        // this.drawerView = 'LDrawerList'
        // this.drawerViewData.list = {
        //   vehicles: markersInside,
        // }
        this.$store.commit('liveMap/onDrawerListView', markersInside)

        this.$store.commit('liveMap/drawerSync', {
          view: 'LDrawerList',
          visibility: true,
        })

        // this.drawerVisibility = true

        // alert(`total selected: ${markersInside.length || 0} vehicles`)
      })

      // on clear draw -> reset selcted vehicles & set listView with current filtered vehicles
      this.map.on(window.L.Draw.Event.DELETED, () => {
        console.log('draw deleted', this.getFilteredData)

        const vehicles = this.getFilteredData
        for (const key in vehicles) {
          vehicles[key].isSelected = false
        }

        this.$store.commit('liveMap/onDrawerListView', vehicles)

        this.$store.commit('liveMap/drawerSync', {
          view: 'LDrawerList',
          visibility: true,
        })
      })
    })
  },
  beforeDestroy() {
    this.$refs.map.mapObject.off('zoomend', this.updateZoom)
  },

  watch: {
    filterLastAppliedAt(updated) {
      // const toCompare = Date.now() - 1
      if (updated && !this.isRealtimeEnabled) {
        // console.log('filterAppliedWatcher')
        this.$nextTick(() => {
          const latLngBounds = []
          for (const k in this.getVehicleList) {
            latLngBounds.push(this.getVehicleList[k].geo.latLng)
          }

          if (latLngBounds.length > 0) {
            this.map.fitBounds(latLngBounds)
          }
        })
      }
    },

    areasLoded(u) {
      if (u) {
        // this.drawServiceAreaOverlays()
        // this.drawOverlays()
      }
    },

    resData: {
      deep: false,
      immediate: true,
      handler(resVal) {
        if (
          resVal &&
          this.$store.getters['liveMap/getSelectedVehicleId'] &&
          this.isRealtimeEnabled
        ) {
          console.log(
            'selected-v',
            resVal[this.$store.getters['liveMap/getSelectedVehicleId']].geo
              .leaflet
          )
          setTimeout(
            () =>
              this.map.flyTo(
                resVal[this.$store.getters['liveMap/getSelectedVehicleId']].geo
                  .leaflet,
                18,
                { duration: 0.85 }
              ),
            500
          )
        }
      },
    },
    zoomLevel(newZoom) {
      console.log('zoomLevel', newZoom)
    },
  },

  methods: {
    getMapTileOptions(type) {
      return {
        type: type,
      }
    },
    getDefaultFleetLatLng() {
      const lat = parseFloat(this.$org?.default_fleet?.warehouse_lat) || 23.8103
      const lng = parseFloat(this.$org?.default_fleet?.warehouse_lon) || 90.4125
      console.log('getDefaultFleetLatLng', latLng(lat, lng))
      return latLng(lat, lng)
    },
    getCurrentVehicleLatLng() {
      return this.resData[this.$store.getters['liveMap/getSelectedVehicleId']]
        .geo.leaflet
    },

    updateZoom() {
      this.zoomLevel = this.$refs.map.mapObject.getZoom()
    },

    onClusterReady: () => {
      /* console.log('onClusterReady', e) */
    },

    onClusterClick: () => {
      // console.log('onClusterClick')
    },

    getMarkerVariant({ isSelected, isFocused, vehicleId }) {
      if (this.$store.state.liveMap.drawer.details.id === vehicleId)
        return 'dark'
      // console.log({ isSelected, isFocused })
      else if (isSelected || isFocused) return 'dark'
      else return 'light'
    },

    getMarkerZIndexOffset({ vehicleId }) {
      if (this.$store.state.liveMap.drawer.details.id === vehicleId) return 10

      return
    },

    // tools
    getRandNum() {
      return Date.now()
    },

    fromCoordsStrToLatLngArr(coords) {
      if (!coords) return []

      // capture between all curly braces excluding the braces
      // though the commented regex is more accurate but the browser support
      // for positive look behind is limited. the first regex is fine but also mathces
      // strings with a single curly brace (edning with '}').
      const list = coords.match(/[^{}]+(?=})/g)
      // const list = coords.match(/(?<=\{)(.*?)(?=\})/g)

      // return mapped array with proper data type
      const mapped = list.map((c) => {
        const [lat, lng] = c.split(',')
        return [Number(lat), Number(lng)]
      })

      // push the first element at the end to complete the shape (as of pixi needs it like so)
      mapped.push(mapped[0])

      return mapped
    },

    async fetchAreas() {
      const reqs = [
        this.$http.get(useEndpoints.dropdown.serviceAreas()),
        this.$http.get(useEndpoints.dropdown.parkingAreas()),
        this.$http.get(useEndpoints.dropdown.slowSpeedAreas()),
        this.$http.get(useEndpoints.dropdown.restrictedAreas()),
      ]

      await this.$http
        .all(reqs)
        .then((responses) => {
          const [service, parking, slowZone, restricted] = responses.map(
            (r) => {
              const data = r.data.data
              // console.log('mapping')
              if (Array.isArray(data)) {
                return data.map((datum) => ({
                  id: datum.id,
                  name: datum.name,
                  coords: this.fromCoordsStrToLatLngArr(datum.coords),
                }))
              }
              return []
            }
          )

          this.areas = {
            service,
            parking,
            slowZone,
            restricted,
          }
          console.log('fetchAreas', this.areas)

          this.areasLoded = true
          /*
          data.data.forEach((serviceArea) => {
            this.areas.service.push({
              id: serviceArea.id,
              name: serviceArea.name,
              coords: this.fromCoordsStrToLatLngArr(serviceArea.coords),
            })
          })
           */
        })
        .catch((err) => {
          console.log({ serviceAreaFetchErr: err })
        })
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '~leaflet-draw/dist/leaflet.draw.css';

.leaflet-map-pane canvas {
  z-index: 10;
}
.leaflet-map-pane svg {
  z-index: 20;
}

.leaflet-tile-pane {
  z-index: 20;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.leaflet-pane {
  z-index: 40;
}

.leaflet-overlay-pane {
  z-index: 40;
}
.leaflet-shadow-pane {
  z-index: 50;
}
.leaflet-marker-pane {
  z-index: 60;
}
.leaflet-tooltip-pane {
  z-index: 65;
}
.leaflet-popup-pane {
  z-index: 70;
}

.leaflet-control {
  position: relative;
  z-index: 80;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 80;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 100;
  pointer-events: none;
}

.map-view {
  height: calc(100vh - 10%);
}
.p-0-i {
  padding: 0;
}

/* draw-marker */
.leaflet-marker-icon.leaflet-interactive {
  border-radius: 10px;
}

.drawer-left {
  // @apply transition-all duration-300 ease-in-out origin-top-left transform;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 40;
  overflow: hidden;

  transition: all 0.3s ease-out;
  transform: transalate(0, 0);
  transform-origin: top left;

  &.is-open {
    width: 430px;
    hieght: 100%;
    left: 40px;
    transform: translateX(-40px);
  }

  // added on open
  &.is-close {
    width: 2.75rem;
    height: 2.5rem;

    transform: translateX(calc((40px - 100%)));
  }
}

.drawer-right {
  @apply absolute top-0 bottom-0 overflow-hidden transition-all duration-300 ease-in-out transform origin-top-right;

  right: 0;
  z-index: 110;

  &.is-open {
    width: 300px;
    @apply h-full;
  }

  &.is-close {
    @apply h-0 w-0;
  }
}

.search-area {
  width: 100%;
  height: 52px;
  border-radius: 7px;
  border: solid 1px #ffffff;
  background-color: #f2f2f2;

  @apply outline-none px-2 appearance-none ring-0 focus:ring-0 focus:outline-none;
}
</style>

<style lang="scss" scoped>
$dbCollapsedHeight: 60px;

.map-container {
  position: relative;
  overflow-y: hidden;
}

.container-height {
  height: 100vh;
}

.dba {
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;
}
.drawer-bottom {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 0ms;
  z-index: 200;

  position: absolute; // fixed
  bottom: 0;

  // height: 78vh;
  width: 93%;
  margin: 0 auto;
  left: 9px;
  right: 9px;

  background-color: #fff;
  padding: 8px 24px 16px;
  box-sizing: border-box;
  box-shadow: /* 0px 10px 20px rgba(0, 0, 0, 0.22) ,*/ 0px 14px 56px
    rgba(0, 0, 0, 0.25);
  transform: translate(0, 100%);
  transition: all 450ms cubic-bezier(0.32, 1, 0.23, 1) 100ms;

  & .is-collapsed {
    max-height: 60px;
  }

  & .is-expanded {
    max-height: 78vh;
    display: block;
    // position: fixed;
    // top: 0;
    transform: translate(0, 0);
  }
}
</style>

<template>
  <div>
    <div
      class="relative mt-px overflow-hidden bg-white rounded-b"
      style="min-height: 78vh"
    >
      <div class="map-container container-height">
        <v-map
          ref="map"
          :zoom="getZoomLevel"
          :maxZoom="18"
          :center="getMapCenterLatLng"
          :options="{ zoomControl: false }"
        >
          <v-control-zoom position="bottomright" />
          <v-icondefault />

          <v-control-layers position="topright" />

          <v-tilelayer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            layer-type="base"
          />

          <v-tilelayer-googlemutant
            v-for="mapTileProvider in mapTileProviders"
            :key="mapTileProvider.type"
            :name="mapTileProvider.name"
            :apikey="apikey"
            :visible="mapTileProvider.visible"
            :options="getMapTileOptions(mapTileProvider.type)"
          />

          <v-marker-cluster
            v-if="!reqInit"
            :options="clusterOptions"
            @clusterclick="onClusterClick()"
            @ready="onClusterReady"
          >
            <v-marker
              v-for="vehicle in getVehicleList"
              :key="vehicle.id"
              :name="vehicle.name"
              :lat-lng="vehicle.geo.leaflet"
              :options="{ riseOnHover: true }"
              :zIndexOffset="getMarkerZIndexOffset({ vehicleId: vehicle.id })"
            >
              <v-icon :icon-anchor="[16, 37]" class-name="">
                <VehicleMarker
                  :vehicle="vehicle"
                  :power-level="vehicle.lock.power_level"
                  :variant="
                    getMarkerVariant({
                      isSelected: vehicle.isSelected,
                      isFocused: vehicle.isFocused,
                      vehicleId: vehicle.id,
                    })
                  "
                />
              </v-icon>

              <v-tooltip>
                <div class="px-4">
                  <b class="font-bold">{{ vehicle.name }}</b>
                  <br />
                  Power: {{ parseFloat(vehicle.lock.power_level).toFixed() }}%
                </div>
              </v-tooltip>
            </v-marker>
          </v-marker-cluster>
          <template v-if="realtimeLocationsOfSelectedVehicle.length > 0">
            <v-marker
              v-for="(geo, geoIndex) in realtimeLocationsOfSelectedVehicle"
              :key="geoIndex"
              :lat-lng="geo.leaflet"
            >
              <v-icon :icon-anchor="[16, 37]" class-name="">
                <img
                  src="@/assets/img/icons/map_marker@3x.png"
                  alt="path"
                  class="w-10 h-10"
                />
              </v-icon>
            </v-marker>
          </template>
        </v-map>
      </div>
    </div>
  </div>
</template>
