<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :exitButton="{ text: 'Cancel', hidden: false }"
    :size-class="`w-full md:w-3/5`"
  >
    <template #header>
      <div class="flex justify-between -mt-8">
        <div class="panel-title">{{ actionText }}</div>
      </div>
    </template>

    <template #footer>
      <AppButton
        variant="primary"
        :text="actionText"
        :is-loading="isReq"
        @click="$refs.submitButton.click()"
      />
    </template>

    <template #default>
      <ValidationObserver
        tag="div"
        class="px-4"
        v-slot="{ handleSubmit }"
        ref="observer"
      >
        <AppInput
          type="text"
          :name="
            $t('components.taskManagement.addEdit.steps.profile.title.title')
          "
          :label="
            $t('components.taskManagement.addEdit.steps.profile.title.title')
          "
          rules="required"
          :placeholder="
            $t(
              'components.taskManagement.addEdit.steps.profile.placeholder.taskTitle'
            )
          "
          v-model="form.title"
        />

        <AppInput
          v-model="form.user"
          type="richselect"
          :name="
            $t('components.taskManagement.addEdit.steps.profile.title.assignTo')
          "
          :label="
            $t('components.taskManagement.addEdit.steps.profile.title.assignTo')
          "
          rules="required"
          :placeholder="
            $t(
              'components.taskManagement.addEdit.steps.profile.placeholder.selectEmployee'
            )
          "
          text-attribute="text"
          value-attribute="value"
          :options="users"
        />

        <AppInput
          type="richselect"
          :name="
            $t('components.taskManagement.addEdit.steps.profile.title.type')
          "
          :label="
            $t('components.taskManagement.addEdit.steps.profile.title.type')
          "
          rules="required"
          :placeholder="
            $t(
              'components.taskManagement.addEdit.steps.profile.placeholder.selectAType'
            )
          "
          :options="[
            {
              value: 'CHARGING',
              text: $t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.charge'
              ),
            },
            {
              value: 'REBALANCING',
              text: $t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.rebalance'
              ),
            },
            {
              value: 'SWAP_BATTERY',
              text: $t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.swapBattery'
              ),
            },
            {
              value: 'MAINTENANCE',
              text: $t(
                'components.taskManagement.addEdit.steps.profile.options.taskType.maintenance'
              ),
            },
          ]"
          hide-search-box
          v-model="form.task_type"
        />

        <AppInput
          type="textarea"
          :name="
            $t(
              'components.taskManagement.addEdit.steps.profile.title.description'
            )
          "
          :label="
            $t(
              'components.taskManagement.addEdit.steps.profile.title.description'
            )
          "
          rules=""
          :placeholder="
            $t(
              'components.taskManagement.addEdit.steps.profile.placeholder.writeDescription'
            )
          "
          v-model="form.description"
        />

        <div class="grid grid-cols-2 gap-2">
          <TaskAddEditStatusDropdown
            v-model="form.task_status"
            :label="
              $t('components.taskManagement.addEdit.steps.profile.title.status')
            "
            :options="taskStatusOptions"
            :disabled="getTaskStatusDisabled"
          />

          <TaskAddEditStatusDropdown
            v-model="form.priority"
            :label="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.priority'
              )
            "
            :options="taskPriorityOptions"
            :disabled="getTaskPriorityDisabled"
          />
        </div>

        <div class="grid grid-cols-2 gap-2">
          <!-- TODO --vehicle qr code --bug in fetch options -->
          <AppInput
            v-model="form.vehicle"
            type="richselect"
            :name="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
              )
            "
            :label="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
              )
            "
            rules="required"
            placeholder="e.g. 10001001"
            text-attribute="text"
            value-attribute="value"
            :options="vehicleOptions"
            :fetch-options="onFetchVehicles"
          />

          <AppInput
            type="date"
            :name="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.dueDate'
              )
            "
            :label="
              $t(
                'components.taskManagement.addEdit.steps.profile.title.dueDate'
              )
            "
            rules="required"
            v-model="form.due_by"
          />
        </div>

        <!-- <UploadFile /> -->

        <!--  -->
        <div class="py-2 border-t border-b">
          <template v-if="form.vehicle">
            <AppFormLabel :text="`Tags`" />
            <OtoTag :entity-id="form.vehicle" :entity-type="'bike'" />
          </template>
          <template v-else>
            <span class="text-sm text-gray-500">{{
              $t(
                'components.taskManagement.addEdit.steps.profile.subText.pleaseSelectAVehicleToAddTags'
              )
            }}</span>
          </template>
        </div>

        <div class="py-2 border-b">
          <template v-if="form.vehicle">
            <notes
              content-type="bike"
              :content-type-instance-id="form.vehicle"
              v-if="form.vehicle && form.vehicle !== ''"
            />
          </template>
          <template v-else>
            <span class="text-sm text-gray-500">{{
              $t(
                'components.taskManagement.addEdit.steps.profile.subText.pleaseSelectAVehicleToAddNotes'
              )
            }}</span>
          </template>
        </div>

        <button
          ref="submitButton"
          type="submit"
          class="hidden"
          @click="handleSubmit(onSave)"
        >
          Save
        </button>
      </ValidationObserver>
    </template>
  </EdgeStack>
</template>
<script>
// import { useEndpoints } from '@/composables'
import Notes from './Notes.vue'
import { EdgeStack } from '@/components/modals'
// import { UploadFile } from '@/components/form'

import TaskAddEditStatusDropdown from './AddEditStatusDropdown.vue'
import { useEndpoints } from '@/composables'
import { deepCompareObjects, xMan } from '@/utils'
import dayjs from 'dayjs'
import OtoTag from '@/composites/tag/OtoTag.vue'

import {
  taskStatusOptions,
  taskPriorityOptions,
  getTaskStatusOptionsByStatus,
} from '.'

const taskAddEditFormModel = (isEditing = false) => ({
  user: '',
  vehicle: '',

  title: '',
  description: '',

  priority: isEditing ? '' : 'H',
  task_type: '',
  task_status: isEditing ? '' : 'TODO',

  due_by: isEditing
    ? ''
    : dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD'),
  files: '',
})
export default {
  name: 'TaskAddEdit',

  components: {
    EdgeStack,
    TaskAddEditStatusDropdown,
    OtoTag,
    // UploadFile,
    Notes,
  },

  props: {
    esId: {
      type: String,
      default: 'task-add-edit',
    },
    busy: {
      type: Boolean,
      default: false,
    },
    primaryKey: {
      required: false,
    },
    formData: {
      type: Object,
      required: false,
    },
    vehicleOptions: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      form: taskAddEditFormModel(this.isEditing),

      users: [],
      isReq: false,

      taskStatusOptions: taskStatusOptions,
      taskPriorityOptions: taskPriorityOptions,
    }
  },

  computed: {
    isEditing() {
      return !!this.formData
    },
    actionText() {
      return this.isEditing
        ? this.$t('components.taskManagement.addEdit.headline.update')
        : this.$t('components.taskManagement.addEdit.headline.add')
    },
    getTaskStatusOptions() {
      if (this.isEditing) return this.taskStatusOptions

      return getTaskStatusOptionsByStatus(
        this.form.task_status,
        this.taskStatusOptions
      )
    },
    getTaskStatusDisabled() {
      return (
        this.isEditing &&
        ['DROPPED', 'CANCELLED'].includes(this.form.task_status)
      )
    },
    getTaskPriorityDisabled() {
      return (
        this.isEditing &&
        ['DROPPED', 'CANCELLED'].includes(this.form.task_status)
      )
    },
  },

  async created() {
    await this.fetchUsers()
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        console.log('formData', data)

        if (data) {
          this.form = { ...data }
        } else {
          this.form = taskAddEditFormModel()
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // todo: should confirm before leaving
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    async fetchUsers() {
      await this.$http
        .get('/dashboard/operators/?dropdown=true')
        .then(({ data }) => {
          this.users = data?.data.map((user) => ({
            value: user.id,
            text: user.full_name,
          }))
          // console.log('fetchUsers', this.users)
        })
        .catch((err) => {
          console.log('fetchUsersErr', err, err.response)
        })
    },

    async onFetchUsers(query) {
      await this.$http
        .get('/dashboard/operators/', {
          params: {
            search: query,
          },
        })
        .then(({ data }) => {
          this.users = data?.data.map((user) => ({
            value: user.id,
            text: user.full_name,
          }))
          // console.log('onFetchUsers', this.users)
        })
        .catch((err) => {
          console.log('onFetchUsersErr', err, err.response)
        })

      return { results: this.users }
    },

    async onFetchVehicles(query) {
      await this.$http
        .get('/dashboard/vehicles/', {
          params: {
            search: query,
            task_status: 'not_in_task',
          },
        })
        .then(({ data }) => {
          this.vehicles = data?.data.map((vehicle) => ({
            text: vehicle.qr_code,
            value: vehicle.id,
          }))
          // console.log('onFetchUsers', this.users)
        })
        .catch((err) => {
          console.log('onFetchUsersErr', err, err.response)
        })

      return { results: this.vehicles }
    },

    async onSave() {
      if (this.busy) return

      this.isReq = true
      const formDataProxy = { ...this.form }
      formDataProxy.due_by = new Date(formDataProxy.due_by).toISOString()
      // formDataProxy.vehicle = 'a016e748-0d27-4334-96fe-47c1349a723d'
      const formData = new xMan(formDataProxy).toFormData()

      this.$http
        .post(useEndpoints.task.create(), formData)
        .then(({ data }) => {
          const verb = this.isEditing ? 'updated' : 'created'
          this.$emit('save', data)

          this.$edgeStack.close(this.esId)
          this.$edgeStack.emitter.on(
            this.$edgeStack.getEventName('closed', this.esId),
            () => {
              this.form = taskAddEditFormModel()
            }
          )

          this.$notify(
            {
              group: 'bottomLeft',
              type: 'success',
              title: `Task has been ${verb}`,
              text: `The task has been successfully ${verb}`,
            },
            5000
          )
        })
        .catch((err) => {
          console.log('onSaveErr', err, err.response)
          this.$notify(
            {
              group: 'bottomLeft',
              type: 'error',
              title: `Error occured [${err?.response?.status}]`,
              text:
                err.response?.data?.detail ??
                `Failed to create task, please try agian.`,
            },
            5000
          )
        })
        .finally(() => (this.isReq = false))
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
}
</style>
