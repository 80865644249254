var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EdgeStack',{attrs:{"id":_vm.esId,"busy":_vm.busy,"exitButton":{ text: 'Cancel', hidden: false },"size-class":"w-full md:w-3/5"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex justify-between -mt-8"},[_c('div',{staticClass:"panel-title"},[_vm._v(_vm._s(_vm.actionText))])])]},proxy:true},{key:"footer",fn:function(){return [_c('AppButton',{attrs:{"variant":"primary","text":_vm.actionText,"is-loading":_vm.isReq},on:{"click":function($event){return _vm.$refs.submitButton.click()}}})]},proxy:true},{key:"default",fn:function(){return [_c('ValidationObserver',{ref:"observer",staticClass:"px-4",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('AppInput',{attrs:{"type":"text","name":_vm.$t('components.taskManagement.addEdit.steps.profile.title.title'),"label":_vm.$t('components.taskManagement.addEdit.steps.profile.title.title'),"rules":"required","placeholder":_vm.$t(
            'components.taskManagement.addEdit.steps.profile.placeholder.taskTitle'
          )},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t('components.taskManagement.addEdit.steps.profile.title.assignTo'),"label":_vm.$t('components.taskManagement.addEdit.steps.profile.title.assignTo'),"rules":"required","placeholder":_vm.$t(
            'components.taskManagement.addEdit.steps.profile.placeholder.selectEmployee'
          ),"text-attribute":"text","value-attribute":"value","options":_vm.users},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}}),_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t('components.taskManagement.addEdit.steps.profile.title.type'),"label":_vm.$t('components.taskManagement.addEdit.steps.profile.title.type'),"rules":"required","placeholder":_vm.$t(
            'components.taskManagement.addEdit.steps.profile.placeholder.selectAType'
          ),"options":[
          {
            value: 'CHARGING',
            text: _vm.$t(
              'components.taskManagement.addEdit.steps.profile.options.taskType.charge'
            ),
          },
          {
            value: 'REBALANCING',
            text: _vm.$t(
              'components.taskManagement.addEdit.steps.profile.options.taskType.rebalance'
            ),
          },
          {
            value: 'SWAP_BATTERY',
            text: _vm.$t(
              'components.taskManagement.addEdit.steps.profile.options.taskType.swapBattery'
            ),
          },
          {
            value: 'MAINTENANCE',
            text: _vm.$t(
              'components.taskManagement.addEdit.steps.profile.options.taskType.maintenance'
            ),
          } ],"hide-search-box":""},model:{value:(_vm.form.task_type),callback:function ($$v) {_vm.$set(_vm.form, "task_type", $$v)},expression:"form.task_type"}}),_c('AppInput',{attrs:{"type":"textarea","name":_vm.$t(
            'components.taskManagement.addEdit.steps.profile.title.description'
          ),"label":_vm.$t(
            'components.taskManagement.addEdit.steps.profile.title.description'
          ),"rules":"","placeholder":_vm.$t(
            'components.taskManagement.addEdit.steps.profile.placeholder.writeDescription'
          )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('TaskAddEditStatusDropdown',{attrs:{"label":_vm.$t('components.taskManagement.addEdit.steps.profile.title.status'),"options":_vm.taskStatusOptions,"disabled":_vm.getTaskStatusDisabled},model:{value:(_vm.form.task_status),callback:function ($$v) {_vm.$set(_vm.form, "task_status", $$v)},expression:"form.task_status"}}),_c('TaskAddEditStatusDropdown',{attrs:{"label":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.priority'
            ),"options":_vm.taskPriorityOptions,"disabled":_vm.getTaskPriorityDisabled},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
            ),"label":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.vehicleQRcode'
            ),"rules":"required","placeholder":"e.g. 10001001","text-attribute":"text","value-attribute":"value","options":_vm.vehicleOptions,"fetch-options":_vm.onFetchVehicles},model:{value:(_vm.form.vehicle),callback:function ($$v) {_vm.$set(_vm.form, "vehicle", $$v)},expression:"form.vehicle"}}),_c('AppInput',{attrs:{"type":"date","name":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.dueDate'
            ),"label":_vm.$t(
              'components.taskManagement.addEdit.steps.profile.title.dueDate'
            ),"rules":"required"},model:{value:(_vm.form.due_by),callback:function ($$v) {_vm.$set(_vm.form, "due_by", $$v)},expression:"form.due_by"}})],1),_c('div',{staticClass:"py-2 border-t border-b"},[(_vm.form.vehicle)?[_c('AppFormLabel',{attrs:{"text":"Tags"}}),_c('OtoTag',{attrs:{"entity-id":_vm.form.vehicle,"entity-type":'bike'}})]:[_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v(_vm._s(_vm.$t( 'components.taskManagement.addEdit.steps.profile.subText.pleaseSelectAVehicleToAddTags' )))])]],2),_c('div',{staticClass:"py-2 border-b"},[(_vm.form.vehicle)?[(_vm.form.vehicle && _vm.form.vehicle !== '')?_c('notes',{attrs:{"content-type":"bike","content-type-instance-id":_vm.form.vehicle}}):_vm._e()]:[_c('span',{staticClass:"text-sm text-gray-500"},[_vm._v(_vm._s(_vm.$t( 'components.taskManagement.addEdit.steps.profile.subText.pleaseSelectAVehicleToAddNotes' )))])]],2),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"},on:{"click":function($event){return handleSubmit(_vm.onSave)}}},[_vm._v(" Save ")])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }